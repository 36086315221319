import React, { useEffect, useState } from 'react';
import axiosInstance from '../../BaseUrl.js';
import './MessagesTable.scss';
import { Modal, Button } from 'react-bootstrap'; // Ensure you have react-bootstrap installed
import { isFlippedState } from '../../store/index.js';
import { useRecoilState } from 'recoil';
const MessagesTable = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [isFlipped, setIsFlipped] = useRecoilState(isFlippedState);
    useEffect(() => {
        const handleScroll = () => {
            setIsFlipped(window.scrollY > -1);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [setIsFlipped]);
    useEffect(() => {
        const token = localStorage.getItem('token');

        if (token) {
            // Fetch data if token exists
            axiosInstance.get('/contact-us', {
                headers: { Authorization: `Bearer ${token}` },
            })
                .then((response) => {
                    setData(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                    setError('Error fetching data');
                    setLoading(false);
                });
        } else {
            setError('No token found. Please log in.');
            setLoading(false);
        }
    }, []);

    const handleDelete = (id) => {
        const token = localStorage.getItem('token');

        axiosInstance.delete(`/contact-us/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(() => {
                setData(data.filter((item) => item.id !== id));
            })
            .catch((error) => {
                console.error('Error deleting message:', error);
                setError('Error deleting message');
            });
    };

    const handleShowModal = (message) => {
        setSelectedMessage(message);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedMessage(null);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="messages-table">
            <h2>Messages</h2>
            <table className="table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => handleShowModal(item)}
                                >
                                    View Message
                                </button>
                                <button
                                    className="btn btn-danger ml-2"
                                    onClick={() => handleDelete(item.id)}
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modal for displaying the message */}
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Message Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedMessage && (
                        <div>
                            <p><strong>Name: </strong>{selectedMessage.name}</p>
                            <p><strong>Email: </strong>{selectedMessage.email}</p>
                            <p><strong>Message: </strong>{selectedMessage.message}</p>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MessagesTable;
