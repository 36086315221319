import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../../store/LanguageContext';
import './ContactSection.scss';
import axiosInstance from '../../BaseUrl.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { isFlippedState } from '../../store/index.js';
import { useRecoilState } from 'recoil';

const ContactSection = () => {
    const { language } = useContext(LanguageContext); // Extract language context
    const [isFlipped, setIsFlipped] = useRecoilState(isFlippedState);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        sendCopy: true,
    });
    const [contactInfo, setContactInfo] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            setIsFlipped(window.scrollY > -5);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [setIsFlipped]);

    useEffect(() => {
        axiosInstance.get('/shared'
            , {
                headers: { 'Accept-Language': language },
            }
        )
            .then((response) => {
                setContactInfo(response.data.contactInfo);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching contact info:', error);
                setLoading(false);
            });
    }, []);

    const handleChange = (e) => {
        const { id, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [id]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Check if required fields are filled
        if (!formData.name || !formData.email || !formData.message) {
            alert(isArabic ? 'يرجى ملء جميع الحقول المطلوبة' : 'Please fill in all required fields');
            return;
        }
    
        // Prepare data for the POST request (only include name, email, message)
        const data = {
            name: formData.name,
            email: formData.email,
            message: formData.message
        };
    
        // Send POST request to the contact-us API endpoint
        axiosInstance.post('/contact-us', data)
            .then((response) => {
                console.log('Form submitted successfully:', response.data);
                // You can add success notification or clear the form
                alert(isArabic ? 'تم إرسال الرسالة بنجاح!' : 'Message sent successfully!');
                // Optionally, reset the form after submission
                setFormData({
                    name: '',
                    email: '',
                    message: '',
                    sendCopy: true,
                });
            })
            .catch((error) => {
                console.error('Error submitting the form:', error);
                alert(isArabic ? 'حدث خطأ أثناء الإرسال' : 'An error occurred while submitting the form');
            });
    };
    
    if (loading) {
        return <div>Loading...</div>;
    }

    // Use Arabic if language is 'ar', otherwise use English
    const isArabic = language === 'ar';

    return (
        <section className="contact-section flexA">
            <div className="container flexA col-12">
                <div className="contact-wrapper col-10 flexA">
                    <div className="contact-info col-12 flexA">
                        <div lang={language} className="contact-card col-lg-3 col-md-11">
                            <div className="icon">
                                <FontAwesomeIcon icon={faEnvelope} />
                            </div>
                            <div className="details">
                                <div className="title">{isArabic ? 'البريد الإلكتروني' : 'Email'}</div>
                                <p>{contactInfo.email}</p>
                            </div>
                        </div>
                        <div lang={language} className="contact-card col-lg-3 col-md-11">
                            <div className="icon">
                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                            </div>
                            <div className="details">
                                <div className="title">{isArabic ? 'العنوان' : 'Address'}</div>
                                <p>{isArabic ? contactInfo.address : contactInfo.address}</p>
                            </div>
                        </div>

                        <div lang={language} className="contact-card col-lg-3 col-md-11">
                            <div className="icon">
                                <a href={`tel:${contactInfo.phone}`} className='A'>
                                    <FontAwesomeIcon icon={faPhone} />
                                </a>
                            </div>
                            <div className="details">
                                <div className="title">{isArabic ? 'الهاتف' : 'Phone'}</div>
                                <a href={`tel:${contactInfo.phone}`} className='A'>
                                    <p>{contactInfo.phone}</p>
                                </a>
                            </div>
                        </div>
                        <div lang={language} className="contact-card col-lg-3 col-md-11">
                            <div className="icon">
                                <a href={`https://api.whatsapp.com/send/?phone=${contactInfo.phone}`} className='A'>
                                    <FontAwesomeIcon icon={faWhatsapp} />
                                </a>
                            </div>
                            <div className="details">
                                <div className="title">{isArabic ? 'واتساب' : 'WhatsApp'}</div>
                                <a href={`https://api.whatsapp.com/send/?phone=${contactInfo.phone}`} className='A'>

                                    <p>{contactInfo.phone}</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-11 flexA">
                <div className='col-5 line'></div>
                <div className='col-2 Contop' >{isArabic ? 'تواصل معنا ' : 'Contact Us'}</div>
                <div className='col-5 line'></div>
            </div>
            <div className="col-11 flexA">

                <div className="Form col-lg-6 col-md-10 col-sm-12">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">{isArabic ? 'الاسم' : 'Name'}</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder={isArabic ? 'أدخل اسمك' : 'Enter your name'}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">{isArabic ? 'البريد الإلكتروني' : 'Email address'}</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder={isArabic ? 'أدخل بريدك الإلكتروني' : 'Enter your email'}
                            />
                            <small id="emailHelp" className="form-text text-muted">
                                {isArabic ? 'لن نشارك بريدك الإلكتروني مع أي شخص آخر.' : 'We\'ll never share your email with anyone else.'}
                            </small>
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">{isArabic ? 'الرسالة' : 'Message'}</label>
                            <textarea
                                className="form-control"
                                id="message"
                                value={formData.message}
                                onChange={handleChange}
                                placeholder={isArabic ? 'أدخل رسالتك' : 'Enter your message'}
                            />
                        </div>
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="sendCopy"
                                checked={formData.sendCopy}
                                onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="sendCopy">
                                {isArabic ? 'أرسل لي نسخة من الرسالة' : 'Send me a copy of the message'}
                            </label>
                        </div>
                        <button type="submit" className="btn btn-primary">
                            {isArabic ? 'إرسال' : 'Submit'}
                        </button>
                    </form>
                </div>
                <div id="map" className="relative col-lg-6 col-md-12">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3631.662247725229!2d39.6555750748141!3d24.4625008610674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15bd95840b62c695%3A0x4417e97f577e8524!2sDMAI4383%2C%204383%20Jabbar%20Ibn%20Sakhr%2C%207981%2C%20Al%20Khalidiyyah%2C%20Madinah%2042317%2C%20Saudi%20Arabia!5e0!3m2!1sen!2sus!4v1726443205628!5m2!1sen!2sus"
                        loading="lazy"
                        allowFullScreen
                        title="Map"
                    />
                </div>

            </div>
        </section>
    );
};

export default ContactSection;
