import React from "react";
import "./WhatsAppIcon.scss"; // Add your SCSS styles

const WhatsAppIcon = () => {
  return (
    <div className="flexR">
      {/* Link to Font Awesome for the WhatsApp icon */}
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
      />
      <a
        href="https://api.whatsapp.com/send/?phone=966591986793" // Replace with your number
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a>

    </div>
  );
};

export default WhatsAppIcon;
