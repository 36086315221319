import React, { useEffect, useState } from 'react';
import baseUrl from '../../BaseUrl'; // Import your base URL configuration
import './BackFaceComponent.scss';

function BackFaceComponent() {
   const [data, setData] = useState([]);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(null);

   useEffect(() => {
      // Use the baseUrl Axios instance to make the request
      baseUrl.get(`/procedures`)
         .then(response => {
            const fetchedData = response.data?.data || []; // Safeguard to ensure 'data' is an array
            setData(fetchedData);
            setLoading(false);
         })
         .catch(error => {
            setError(error);
            setLoading(false);
         });
   }, []);

   if (loading) return <p>Loading...</p>;
   if (error) return <p>Error fetching data: {error.message}</p>;

   // Limit the number of items to 6
   const limitedData = data.slice(0, 6);

   return (
      <div className="backFace">
         {limitedData.map((item) => (
            <div className='imageText' key={item.id}>
               <img className='image' src={item.iconUrl} alt={item.name} />
               <p className='imageDescribtion'>{item.name}</p>
            </div>
         ))}
      </div>
   );
}

export default BackFaceComponent;
