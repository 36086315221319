import React from 'react';
import './Spinner.scss';
const Spinner = () => (
    <div className='col-12 flex 'style={{height:'60vh'}}>
        <span class="loader">DINA KHAIRY</span>
        <span class="loader2"></span>
    </div>
);

export default Spinner;
